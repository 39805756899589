import { S25Util } from "../../util/s25-util";
import { EventMircotI } from "../s25-event/EventMicroI";
import { SpaceService } from "../../services/space.service";
import { ResourceService } from "../../services/resource.service";
import { Table } from "../s25-table/Table";
import { S25Event } from "../s25-event/EventMicroI";
import { S25Profile } from "../s25-event/ProfileI";

declare global {
    interface Window {
        angBridge: any;
    }
}

export const S25ObjectMap: Record<number, ObjectTypeMapping> = {
    4: {
        name: {
            singular: "space",
            plural: "spaces",
        },
        nodeId: "space_id",
        nodeName: "space_name",
        nodeRsrv: "space_reservation",
        objectType: "location",
        columns: [
            { id: "add", header: " ", sortable: false },
            { id: "space_name", header: "Name" },
            { id: "formal_name", header: "Title" },
            // {id: "instructions", header: "Instructions"},
            // {id: "layout", header: "Layout"},
            { id: "max_capacity", header: "Default Capacity" },
            { id: "available_dates", header: "Availability" },
            { id: "rating_avg", header: "Location Satisfaction" },
            { id: "conflicts", header: "Conflict Details", sortable: false },
            { id: "building_id", header: "Building" },
        ],
        searchQuery: SpaceService.getSpacesBySearchQuery,
    },
    6: {
        name: {
            singular: "resource",
            plural: "resources",
        },
        nodeId: "resource_id",
        nodeName: "resource_name",
        nodeRsrv: "resource_reservation",
        objectType: "resource",
        columns: [
            { id: "add", header: " Add", sortable: false },
            { id: "resource_name", header: "Name" },
            // {id: "instructions", header: "Instructions"},
            { id: "stock_level", header: "Quantity Available", sortable: false },
            { id: "conflicts", header: "Conflict Details", sortable: false },
        ],
        searchQuery: ResourceService.getResourcesBySearchQuery,
    },
};
export interface ObjectTypeMapping {
    name: { singular: string; plural: string };
    nodeId: string;
    nodeName: string;
    nodeRsrv: string;
    objectType: "resource" | "location";
    columns: Table.Column[];
    searchQuery: SearchQueryFunction;
}

type SearchQueryFunction = (searchQuery?: string, includes?: string[], dataScope?: string) => Promise<any>;
