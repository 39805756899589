import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    Output,
    EventEmitter,
    ViewEncapsulation,
    ChangeDetectionStrategy,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Item } from "../../pojo/Item";
import { S25Util } from "../../util/s25-util";
import { AvailLocationItems, AvailResourceItems } from "./s25.object.search.util";
import { EventOccUtil } from "../s25-event/s25-event-occurrences/s25-event-occurrence/s25.event.occurrence.util";
import { EventMircotI } from "../s25-event/EventMicroI";
import { S25Reservation } from "../s25-event/ReservationI";
type AddItemType = AvailLocationItems | AvailResourceItems;
@TypeManagerDecorator("s25-ng-object-add-button")
@Component({
    selector: "s25-ng-object-add-button",
    template: `
        @if (init) {
            <div class="object-add-button-wrapper">
                @if (selectable) {
                    <span class="selectable-checkbox">
                        <s25-ng-checkbox
                            s25-ng-shift-selectable
                            [shiftSelectIndex]="buttonNumber"
                            [shiftSelectGroup]="'AddItems'"
                            [(modelValue)]="isChecked"
                            (modelValueChange)="onCheckBoxClick($event)"
                            [ariaLabel]="'Select Item'"
                            [noLabel]="true"
                        ></s25-ng-checkbox>
                    </span>
                }

                @if (showAddQuantity) {
                    <span class="display-button-text"
                        ><input
                            type="number"
                            min="0"
                            [max]="max"
                            class="c-input c-margin-right--single"
                            id="quantity"
                            [(ngModel)]="quantity"
                            (change)="onQuantityChange()"
                    /></span>
                }
                <button [class]="buttonClass" (click)="click()" [disabled]="disabledButton">{{ buttonText }}</button>
            </div>
        }
    `,
    styles: [
        `
            input[type="number"] {
                width: 80px;
            }

            .object-add-button-wrapper {
                display: flex;
                flex-direction: row;
                gap: 15px; /* Adjust the space between the columns */
                align-items: center; /* Align items vertically in the middle */
                width: 100%; /* Ensure the wrapper spans the available width */
            }

            .selectable-checkbox {
                flex: 1; /* Take up equal width in the row */
                display: flex;
                justify-content: flex-start; /* Align checkbox to the left */
            }

            .display-button-text {
                flex: 1; /* Take up equal width in the row */
                display: flex;
                justify-content: flex-start; /* Align input to the left */
            }

            button {
                margin-left: auto; /* Push the button to the far right */
            }
        `,
    ],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ObjectAddButtonComponent implements OnInit {
    @Input() data?: AvailLocationItems | AvailResourceItems;
    @Input() itemTypeId: Item.Ids;
    @Input() occ?: S25Reservation;
    @Output() onButtonClick: EventEmitter<AddButtonOutput> = new EventEmitter<AddButtonOutput>();

    init: boolean = false;
    buttonText: string;
    buttonClass: string;
    disabledButton: boolean = false;
    showAddQuantity: boolean = false;
    quantity: number = 1;
    getAddedItems: any[] = [];
    max: number = 1;
    buttonNumber: number;
    private static count = 0;
    selectable: boolean = true;
    isChecked: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
        this.buttonNumber = S25ObjectAddButtonComponent.count;
        S25ObjectAddButtonComponent.count++;
    }

    ngOnInit() {
        if (this.itemTypeId === Item.Ids.Location) {
            this.getAddedItems =
                this.occ.locations[0]?.requested.concat(
                    this.occ.locations[0]?.draft,
                    this.occ.locations[0]?.reserved,
                ) || [];
        } else {
            this.data?.quantity !== "Unlimited" ? (this.max = this.data.quantity) : (this.max = 65536);
            this.getAddedItems =
                this.occ.resources[0]?.requested.concat(
                    this.occ.resources[0]?.draft,
                    this.occ.resources[0]?.reserved,
                ) || [];
        }

        let nodeName = this.itemTypeId === Item.Ids.Location ? "space_id" : "resource_id";

        let find = this.getAddedItems.find((item) => item.itemId === this.data?.[nodeName]);

        if (find) {
            this.buttonText = "Added";
            this.buttonClass = "aw-button aw-button--default";
            this.disabledButton = true;
            this.selectable = false;
        } else if (this.data?.checkedObj.availableDatesWithOverrides <= 0) {
            this.buttonText = "Unavailable";
            this.buttonClass = "aw-button aw-button--default";
            this.disabledButton = true;
            this.selectable = false;
        } else {
            this.buttonText =
                (["C", "F"].indexOf(this.data?.assign_perm) > -1 ? "Reserve" : "Request") +
                (S25Util.toBool(this.data?.hasConflicts) ? " Available" : "");
            this.buttonClass = S25Util.toBool(this.data?.hasConflicts)
                ? "aw-button aw-button--warning"
                : ["C", "F"].indexOf(this.data.assign_perm) > -1
                  ? "aw-button aw-button--success"
                  : "aw-button aw-button--primary";
            if (this.itemTypeId === Item.Ids.Resource) this.showAddQuantity = true;
        }
        this.init = true;
        this.cd.detectChanges();
    }

    click(e: boolean) {
        this.buttonText = "Added";
        this.buttonClass = "aw-button aw-button--default";
        this.disabledButton = true;
        this.showAddQuantity = false;
        this.selectable = false;
        this.cd.detectChanges();
        this.doneCheckBoxClick(e, false);
    }

    onCheckBoxClick(e: boolean) {
        this.doneCheckBoxClick(e, true);
    }

    async doneCheckBoxClick(e: boolean, multiSelected: boolean) {
        const objType = this.itemTypeId === Item.Ids.Location ? "location" : "resource";
        const data: AddItemType[] = (await EventOccUtil.normalizeAddRsrvWSData(
            this.data,
            this.getAddedItems,
            objType,
            this.quantity,
        )) as AddItemType[];
        this.onButtonClick.emit({ data: data, isAdd: e, multiSelected: multiSelected });
        this.cd.detectChanges();
    }

    onQuantityChange() {
        if (this.quantity > this.max) this.quantity = this.max;
        if (this.isChecked) this.doneCheckBoxClick(true, true); //  update checked item quantity when  quantity changed
        this.cd.detectChanges();
    }
}

export type AddButtonOutput = {
    data?: EventMircotI;
    isAdd?: boolean;
    multiSelected?: boolean;
};
